.Badge {
	@apply font-medium text-2xs px-1.5 py-px rounded whitespace-nowrap;
	line-height:20px;
	&.Badge--green {
		@apply bg-green-200 text-green-600;
	}
	&.Badge--blue {
		@apply bg-blue-100 text-blue-600;
	}
	&.Badge--red {
		@apply bg-red-200 text-red-600;
	}
	&.Badge--orange {
		@apply bg-orange-200 text-orange-600;
	}
	&.Badge--purple {
		@apply bg-purple-200 text-purple-600;
	}
	&.Badge--gray {
		@apply bg-gray-200 text-gray-700;
	}
	&.Badge--xs {
		font-size:11px;
		line-height:14px;
		.Icon {
			&:first-child:last-child {
				width:14px;
				height:14px;
			}
			svg {
				width:10px;
				height:10px;
			}
		}
	}
	&.Badge--sm {
		@apply text-3xs;
		line-height:18px;
		.Icon {
			&:first-child:last-child {
				width:18px;
				height:18px;
			}
		}
	}
	&.Badge--xl {
		@apply text-xs px-3;
		line-height:36px;
		.Icon {
			&:first-child:last-child {
				width:36px;
				height:36px;
			}
			svg {
				width:16px;
				height:16px;
			}
		}
	}
	&.Badge--rounded-full {
		@apply rounded-full;
	}
	&.Badge--icon-only {
		padding-left:1px;
		padding-right:1px;
	}
	&.Badge--absolute {
		position:absolute;
		right:0;
		top:0;
	}
}
.Body {
	@apply flex items-center;
}
.Label, .Icon {
	@apply mr-1;
	&:last-child {
		@apply mr-0;
	}
}
.Icon {
	@apply relative flex items-center justify-center;
	width:14px;
	height:14px;
	&:first-child:last-child {
		width:20px;
		height:20px;
	}
	svg {
		width:12px;
		height:12px;
	}
}