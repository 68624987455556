@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
	--sidebar-width:320px;
	--sidebar-collapsed-width:80px;
	--additional-sidebar-width:280px;
	--second-sidebar-width:340px;
	--topbar-height:60px;
	--default-line-height:1.5;
}
@screen lg {
	@media (hover: hover) {
		::-webkit-scrollbar {
			width:10px;
			height:10px;
			border:2px solid white;
		}
		::-webkit-scrollbar-track {
			@apply bg-gray-200 rounded-lg border-2 border-white;
			border-radius:6px;
			border:2px solid white;
		}
		::-webkit-scrollbar-thumb {
			@apply bg-gray-500 rounded-lg border-2 border-white;
			border-radius:6px;
			border:2px solid white;
		}
		::-webkit-scrollbar-thumb:hover {
			@apply bg-primary-500;
		}
		.scrollbar-inline {
			::-webkit-scrollbar {
				border:none;
			}
		}
	}
}
::-webkit-input-placeholder {
	@apply text-gray-600 #{!important};
}
:-ms-input-placeholder {
	@apply text-gray-600 #{!important};
}
::placeholder {
	@apply text-gray-600 #{!important};
}
html {
	&.dark {
		@apply bg-gray-900 text-white;
	}
}
body {
	line-height:var(--default-line-height);
	overflow:hidden;
	@apply text-md font-normal;
	&.light, &.auth {
		overflow-y:scroll;
	}
}
.container, .container-narrow {
	@apply px-6;
	@media (min-width: 992px) {
		@apply px-8;
	}
	[class^="container"] {
		@apply px-0;
	}
	&.container-np {
		@apply px-0;
		[class^="container"] {
			@apply px-6;
			@media (min-width: 992px) {
				@apply px-8;
			}
		}
	}
	&:not(.container-np) {
		.container {
			max-width:calc(theme('maxWidth.2xl') - (theme('spacing.6') * 2));
			@media (min-width: 992px) {
				max-width:calc(theme('maxWidth.2xl') - (theme('spacing.8') * 2));
			}
		}
		.container-narrow {
			max-width:calc(theme('maxWidth.lg') - (theme('spacing.6') * 2));
			@media (min-width: 992px) {
				max-width:calc(theme('maxWidth.lg') - (theme('spacing.8') * 2));
			}
		}
	}
}
.container-full {
	@apply w-full;
}
.container {
	@apply max-w-screen-2xl mx-auto;
}
.container-narrow {
	@apply max-w-screen-lg mx-auto;
}
.wysiwyg.wysiwyg {
	--default-line-height:1.65;
	line-height:var(--default-line-height);
	h1, .h1 {
		@apply text-2xl font-bold mb-4;
		&:last-child {
			@apply mb-0;
		}
	}
	h2, .h2 {
		@apply text-xl font-bold mb-3;
		&:last-child {
			@apply mb-0;
		}
	}
	h3, .h3 {
		@apply text-lg font-bold mb-3;
		&:last-child {
			@apply mb-0;
		}
	}
	h4, .h4 {
		@apply text-md font-semibold mb-2;
		&:last-child {
			@apply mb-0;
		}
	}
	h5, .h5 {
		@apply text-md underline mb-1;
		&:last-child {
			@apply mb-0;
		}
	}
	p, .p, {
		@apply mb-4;
		&:last-child {
			@apply mb-0;
		}
		b, strong, em {
			@apply font-semibold;
		}
	}
	ul {
		list-style:disc;
		@apply pl-8;
		li {
			&.public-DraftStyleDefault-depth1 {
				@apply ml-4;
				list-style:circle;
			}
			&.public-DraftStyleDefault-depth2 {
				@apply ml-8;
				list-style:square;
			}
			&.public-DraftStyleDefault-depth3 {
				@apply ml-12;
				list-style:disc;
			}
			&.public-DraftStyleDefault-depth4 {
				@apply ml-16;
				list-style:circle;
			}
			&.public-DraftStyleDefault-depth5 {
				@apply ml-20;
				list-style:square;
			}
		}
	}
	ol {
		list-style:none;
		@apply pl-8;
		li {
			position:relative;
			&:before {
				content:counter(ol0) ". ";
    			counter-increment:ol0;
    			position:absolute;
    			text-align:right;
    			width:30px;
    			left:-36px;
    			top:0;
			}
			&.public-DraftStyleDefault-reset {
				counter-reset:ol0;
			}
			&.public-DraftStyleDefault-depth1 {
				@apply ml-4;
				&:before {
					content:counter(ol1,lower-latin) ". ";
    				counter-increment:ol1;
				}
				&.public-DraftStyleDefault-reset {
					counter-reset:ol1;
				}
			}
			&.public-DraftStyleDefault-depth2 {
				@apply ml-8;
				&:before {
					content:counter(ol2,lower-roman) ". ";
    				counter-increment:ol2;
				}
				&.public-DraftStyleDefault-reset {
					counter-reset:ol2;
				}
			}
			&.public-DraftStyleDefault-depth3 {
				@apply ml-12;
				&:before {
					content:counter(ol3,decimal) ". ";
    				counter-increment:ol3;
				}
				&.public-DraftStyleDefault-reset {
					counter-reset:ol3;
				}
			}
			&.public-DraftStyleDefault-depth4 {
				@apply ml-16;
				&:before {
					content:counter(ol4,lower-latin) ". ";
    				counter-increment:ol4;
				}
				&.public-DraftStyleDefault-reset {
					counter-reset:ol4;
				}
			}
			&.public-DraftStyleDefault-depth5 {
				@apply ml-20;				
				&:before {
					content:counter(ol5,lower-roman) ". ";
    				counter-increment:ol5;
				}
				&.public-DraftStyleDefault-reset {
					counter-reset:ol5;
				}
			}
		}
	}
	ul, ol {
		@apply mb-4;
		&:last-child {
			@apply mb-0;
		}
		li {
			@apply mb-2 last:mb-0;
		}
	}
	a {
		@apply text-primary-500 font-medium;
		&:hover {
			@apply text-primary-600;
		}
	}
	hr {
		@apply mt-8 pt-8 border-t border-gray-300;
	}
	table {
		position:relative;
		height:74px;
		@apply mb-4 w-full bg-gray-200 rounded;
		&:hover {
			@apply shadow-button-focus;
		}
		&:last-child {
			@apply mb-0;
		}
		& > div > span {
			display:block;
			height:26px;
			line-height:26px;
			@apply px-3 text-3xs text-primary-500;
		}
		&:before, &:after, & > div:before, & > div:after, & > div > span:before, & > div > span:after {
			content:"";
			position:absolute;
			top:26px;
			width:calc(33% - 2px);
			background:red;
			left:2px;
			height:22px;
			@apply rounded bg-white;
		}
		&:after {
			left:calc(33% + 2px);
		}
		& > div:before {
			left:calc(66% + 2px);
			width:calc(33% - 1px);
		}
		& > div:after {
			top:50px
		}
		& > div > span:before {
			left:calc(33% + 2px);
			top:50px;
		}
		& > div > span:after {
			left:calc(66% + 2px);
			width:calc(33% - 1px);
			top:50px;
		}
	}
	blockquote {
		@apply mb-4 pl-4 py-2 text-sm text-gray-900 border-l-2 border-gray-300;
		&:last-child {
			@apply mb-0;
		}
	}
	&.wysiwyg--sm {
		h1, .h1 {
			@apply text-xl;
		}
		h2, .h2 {
			@apply text-lg;
		}
		h3, .h3 {
			@apply text-md;
		}
		h4, .h4 {
			@apply text-sm;
		}
		h5, .h5 {
			@apply text-2xs;
		}
		p, .p, li, blockquote {
			@apply text-sm;
		}
		.small {
			font-size:85%;
		}
	}
	&.wysiwyg--xs {
		h1, .h1 {
			@apply text-xl;
		}
		h2, .h2 {
			@apply text-lg;
		}
		h3, .h3 {
			@apply text-md;
		}
		h4, .h4 {
			@apply text-sm;
		}
		h5, .h5 {
			@apply text-3xs;
		}
		p, .p, li, blockquote {
			@apply text-xs;
		}
		.small {
			font-size:85%;
		}
	}
	&.wysiwyg--2xs {
		h1, .h1 {
			@apply text-lg;
		}
		h2, .h2 {
			@apply text-md;
		}
		h3, .h3 {
			@apply text-sm;
		}
		h4, .h4 {
			@apply text-xs;
		}
		h5, .h5 {
			@apply text-2xs;
		}
		p, .p, li, blockquote {
			@apply text-2xs;
		}
		.small {
			font-size:85%;
		}
	}
	.small {
		font-size:85%;
	}
}
h1, h2, h3, h4, h5 {
	line-height:1.25;
}
pre {
	@apply w-full block;
}
code {
	@apply rounded bg-gray-200 text-3xs leading-5 px-3 py-1 text-gray-700 max-w-full inline-flex overflow-x-auto;
}
input[type="time"] {
	text-align:center;
}
input[type="time"]::-webkit-calendar-picker-indicator {
	background:none;
	display:none;
}
input[type="date"]::-webkit-calendar-picker-indicator {
	background:none;
	display:none;
}
input[type="datetime"]::-webkit-calendar-picker-indicator {
	background:none;
	display:none;
}
input[type="datetime-local"]::-webkit-calendar-picker-indicator {
	background:none;
	display:none;
}
fieldset {
	display:contents;
}
button, :focus-visible {
	outline:none !important;
}
img {
	width:100%;
}
.react-datepicker {
	position:relative;
	@apply p-6;
	.react-datepicker__aria-live {
		border:0;
		clip-path:circle(0);
		height:1px;
		margin:-1px;
		overflow:hidden;
		padding:0;
		position:absolute;
		white-space:nowrap;
		width:1px;
	}
	.react-datepicker__navigation {
		position:absolute;
		width:30px;
		height:30px;
		overflow:hidden;
		text-indent:-9999px;
		@apply flex items-center justify-center top-6;
		span {
			display:block;
			width:10px;
			height:10px;
			position:relative;
			&:before, &:after {
				content:"";
				position:absolute;
				left:2px;
				width:7px;
				height:2px;
				top:50%;
				margin-top:-1px;
				@apply transition-move duration-300 ease-out-cubic bg-gray-900 rounded-full;
			}
			&:before {
				@apply rotate-45 -translate-y-0.5;
			}
			&:after {
				@apply -rotate-45 translate-y-0.5;
			}
		}
		&.react-datepicker__navigation--previous {
			@apply rotate-180 left-6;
		}
		&.react-datepicker__navigation--next {
			@apply right-6;
		}
	}
	.react-datepicker__current-month {
		height:30px;
		line-height:30px;
		@apply text-center text-xs mb-5;
	}
	.react-datepicker__day-names {
		@apply grid grid-cols-7 mb-3;
	}
	.react-datepicker__day-name {
		@apply text-gray-600 flex items-center justify-center text-3xs;
	}
	.react-datepicker__month {
		padding:0 1px 1px 0;
	}
	.react-datepicker__week {
		@apply grid grid-cols-7;
	}
	.react-datepicker__day {
		position:relative;
		aspect-ratio:1/1;
		margin-bottom:-1px;
		margin-right:-1px;
		cursor:pointer;
		@apply border border-gray-200 flex items-center justify-center text-xs;
		&:hover {
			@apply bg-gray-200;
		}
		&.react-datepicker__day--weekend {
			//
		}
		&.react-datepicker__day--today {
			//
		}
		&.react-datepicker__day--in-range, &.react-datepicker__day--in-selecting-range {
			@apply bg-primary-200 text-primary-700 border-primary-200;
			&:hover {
				@apply bg-primary-300 border-primary-300;
			}
		}
		&.react-datepicker__day--selected, &.react-datepicker__day--range-start, &.react-datepicker__day--selecting-range-start, &.react-datepicker__day--range-end, &.react-datepicker__day--selecting-range-end {
			@apply bg-primary-500 text-white border-primary-500;
			&:hover {
				@apply bg-primary-600 border-primary-600;
			}
		}
		&.react-datepicker__day--outside-month {
			cursor:default;
			@apply opacity-25 border-transparent;
			&:hover {
				@apply bg-transparent;
			}
		}
	}
}