.Layer {
	pointer-events:none;
	z-index:1000;
	@apply rounded bg-white border-none text-gray-900 shadow text-3xs px-3 py-2 max-w-sm;
	&.Layer--dark {
		@apply bg-gray-900 text-white;
	}
}
.Body {
	position:relative;
}
.Arrow {
	z-index:100;
}